<template>
    <v-card class="rounded" :loading="loading || loadingObjectives || loadingWorkingDays" :loader-height="7">
        <div class="light-grey w-full h-2"></div>
        <div class="py-4 px-6">
            <div v-if="aggregate" class="flex justify-between">
                <div class="flex flex-col justify-between">
                    <h6 class="text-medium-grey font-bold text-lg uppercase mb-4">{{ monthNameRO }} - ECHIPA <span
                            v-if="isTeamObjective"
                            class="bg-green-500 text-white text-xl font-bold mx-2 px-1 py-0.5 rounded">X2</span></h6>
                    <div>
                        <v-label>Valoare vanzari</v-label>
                        <p class="text-2xl text-black font-bold mb-2">{{ $options.filters.formatRon(aggregate) }}
                        </p>
                        <v-label>CA totala</v-label>
                        <p class="text-2xl text-black font-bold mb-0">{{ $options.filters.formatRon(totals) }}
                        </p>
                    </div>
                </div>
                <MonthlyObjective :monthly-amount-objective="monthlyAmountObjective"
                    :monthly-objective="monthlyObjective" :size="100" />
            </div>
            <div v-else>
                <h6 class="text-medium-grey font-bold text-lg uppercase mb-4">{{ monthNameRO }}</h6>
                <v-label>Nu sunt cifre pentru luna curenta</v-label>
            </div>
        </div>
    </v-card>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";
import MonthlyObjective from "@/components/Setari/Objectives/MonthlyObjective"

export default {
    name: "TeamProductieMonthlyStats",
    components: {
        MonthlyObjective,
    },
    props: {
        monthFirstDay: String
    },
    data() {
        return {
            today: new Date().toISOString().split('T')[0]
        }
    },
    computed: {
        ...mapState('userss', {
            loading: state => state.loadingAgentMonthlyAggregate,
        }),
        ...mapState('objectives', {
            loadingObjectives: state => state.loadingAllFenceObjectives,
            loadingWorkingDays: state => state.loadingWorkingDays,
        }),
        ...mapGetters('userss', {
            aggregate: 'getAllAgentsAggregate',
            totals: 'getAllAgentsTotals'
        }),
        ...mapGetters('objectives', {
            getMonthObjective: 'getCurrentMonthAllUsersObjectives',
            getWorkingDays: 'getCurrentMonthWorkingDays'
        }),
        monthNameRO() {
            return new Date().toLocaleString('ro-RO', { month: 'long' })
        },
        isTeamObjective() {
            return this.monthlyObjective >= 100
        },
        selectedMonthObjective() {
            return this.getMonthObjective
        },
        selectedMonthWorkingDays() {
            return this.getWorkingDays(new Date(this.monthFirstDay))
        },
        monthlyAmountObjective() {
            if (!!this.selectedMonthWorkingDays && !!this.selectedMonthObjective) {
                const objectiveAmount = Number(this.selectedMonthObjective) * Number(this.selectedMonthWorkingDays)
                return Math.round(objectiveAmount)
            }
        },
        monthlyObjective() {
            const objective = this.aggregate / this.monthlyAmountObjective * 100
            return objective ? Math.floor(objective) : 0
        },
    },
    methods: {
        ...mapActions('objectives', ["fetchAllFenceObjectives"])
    },
    async created() {
        await this.fetchAllFenceObjectives()
    }
}

</script>